exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-freelance-wordpress-developer-js": () => import("./../../../src/pages/freelance-wordpress-developer.js" /* webpackChunkName: "component---src-pages-freelance-wordpress-developer-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-policies-js": () => import("./../../../src/pages/policies.js" /* webpackChunkName: "component---src-pages-policies-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-web-developer-brighton-js": () => import("./../../../src/pages/web-developer-brighton.js" /* webpackChunkName: "component---src-pages-web-developer-brighton-js" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */)
}

